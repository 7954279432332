<app-rp-loader></app-rp-loader>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <!--  <h4 class="mb-0 font-size-18">View Faq's</h4> -->
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>


      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="wrapper">
          <div class="content-container">
            <div class="card-body" style="height: 600px;">

              <accordion [closeOthers]="true" class="accordion custom-accordian" [isAnimated]="true">
                <accordion-group *ngIf="role == (listOfRoles.SUPERADMIN)" [isOpen]="isFirstOpen"
                  heading="1. Battery Status Report" class="accordion-item" #group>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Project <span class="required">*</span></label>

                          <ng-select [items]="projectsList" placeholder="1.Select Projects" bindLabel="name"
                            bindValue="id" [(ngModel)]="selectedProject" (change)="onProjectFilterChange($event)"
                            (add)="onSelectProject($event)" [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="selectedProject.length==0&&ProjectValidationMessage" class="text-danger mt-2">
                            {{ ProjectValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utility <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="selectedUtility" (change)="onFilterUtilitiesChange($event)"
                            (add)="onSelectUtility($event)" [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="selectedUtility.length==0&&utilityValidationMessage" class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Battery Level</label>
                          <ng-select [items]="batteryLevel" [(ngModel)]="selectedBatteryLevel" bindLabel="name"
                            bindValue="id" [multiple]="false" [deselectOnClick]="false" [clearable]="false"></ng-select>
                        </div>
                      </div>

                      <div class="col-lg-3 d-flex align-items-start sm-btn mt-4">
                        <button class="btn btn-primary btn-md " (click)="export()"> Export
                        </button>
                      </div>

                    </div>
                  </div>
                </accordion-group>

                <!-- Settlemet report -->
                <accordion-group *ngIf="role == (listOfRoles.SUPERADMIN)" [isOpen]="isopen"
                  heading="2. Settlement Report" class="accordion-item" #group>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Project <span class="required">*</span></label>
                          <ng-select [items]="projectsList" placeholder="Select Projects" bindLabel="name"
                            bindValue="id" [(ngModel)]="selectedSettlementProject"
                            (change)="onProjectSettlementFilterChange($event)" (add)="onSelectSettlmentProject($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="selectedSettlementProject.length==0&&ProjectSettlmentValidationMessage"
                            class="text-danger mt-2">
                            {{ ProjectSettlmentValidationMessage }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">payment Date <span class="required">*</span></label>
                          <div class="position-relative">
                            <input type="text" class="form-control settlement"
                              [value]="settlemetDateRange ? (formatDateRange(settlemetDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="settlemetDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarsettlement()"></span>
                            <!-- Clear icon -->
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="settlemetDateRange.length>0"
                              (click)="clearDateSettlement()"></span>

                          </div>
                          <div *ngIf="settlemetDateRange.length==0&&dateRangeSettlementValidationMessage"
                            class="text-danger mt-2">
                            {{ dateRangeSettlementValidationMessage }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 d-flex align-items-start sm-btn mt-4">
                        <button class="btn btn-primary btn-md " (click)="exportSettlemet()"> Export
                        </button>
                      </div>
                    </div>
                  </div>


                </accordion-group>

                <accordion-group *ngIf="role == (listOfRoles.ADMIN)" [isOpen]="true" heading="1. Activation Status"
                  class="accordion-item" #group1>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group1?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="selectedUtility" (change)="onFilterUtilitiesChange($event)"
                            (add)="onSelectUtility($event)" [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="selectedUtility.length==0&&utilityValidationMessage" class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date Range </label>
                          <div class="position-relative">
                            <input type="text" class="form-control date"
                              [value]="activationDateRange ? (formatDateRange(activationDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="activationDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                            <!-- Clear icon -->
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="activationDateRange.length>0"
                              (click)="clearDate()"></span>

                          </div>
                          <div *ngIf="activationDateRange.length==0&&dateRangeValidationMessage"
                            class="text-danger mt-2">
                            {{ dateRangeValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportActionStatus()"> Export
                        </button>
                      </div>
                    </div>
                  </div>
                </accordion-group>

                <accordion-group *ngIf="role == (listOfRoles.ADMIN)" [isOpen]="isConsumptionOpen"
                  heading="2. Consumption Reports" class="accordion-item" #group2>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="consumption_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)" (add)="onSelectUtilityInConsumption($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="utilityValidationMessage" class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date Range <span class="required">*</span></label>
                          <div class="position-relative">
                            <input type="text" class="form-control dateconsumetion"
                              [value]="consumptionDateRange ? (formatDateRange(consumptionDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="consumptionDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarConsumption()"></span>
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="consumptionDateRange.length > 0"
                              (click)="clearConsumptionDate()"></span>

                          </div>
                          <div *ngIf="dateRangeValidationMessage" class="text-danger mt-2">
                            {{ dateRangeValidationMessage }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Flat Number </label>
                          <ng-select [items]="flatnumbersList" placeholder="Select Flats" bindLabel="flatNo"
                            bindValue="id" [(ngModel)]="Consumption_selectedFlatNumber"
                            (change)="onFilterFlatsChange($event)" (add)="onSelectFlatsInConsumption($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.flatNo }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.flatNo">{{ item?.flatNo }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <!--  <div *ngIf="flatNumberValidationMessage" class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div> -->
                        </div>
                      </div>
                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportConsumptionReport()"> Export
                        </button>
                      </div>
                    </div>
                  </div>

                </accordion-group>


                <accordion-group *ngIf="role == (listOfRoles.ADMIN)" [isOpen]="false"
                  [heading]="billingType === 'Prepaid' ? '3. Invoice Consumption Reports' : '3. Bill Wise Consumption Reports'"
                  class="accordion-item" #group3>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="billwise_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)"
                            (add)="onSelectUtilityInBillWiseConsumption($event)" [multiple]="true"
                            [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="billwise_selectedUtility==0&&utilityValidationMessage" class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Months <span class="required">*</span></label>
                          <div class="position-relative">
                            <ng-select [items]="monthsList" placeholder="Select Month" bindLabel="name" bindValue="id"
                              [(ngModel)]="selectedMonth" (change)="onFilterMonthsChange($event)" [multiple]="false"
                              [deselectOnClick]="true" [clearable]="false">

                              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </ng-template>

                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                  <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                </div>
                                <div class="ng-value" *ngIf="items.length > 2">
                                  <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                                </div>
                              </ng-template>
                            </ng-select>
                            <div *ngIf="selectedMonth==null&&monthValidationMessage" class="text-danger mt-2">
                              {{ monthValidationMessage }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Flat Number <span class="required">*</span></label>
                          <ng-select [items]="flatnumbersList" placeholder="Select Flats" bindLabel="flatNo"
                            bindValue="id" [(ngModel)]="billwise_selectedFlatNumber"
                            (change)="onFilterFlatsChange($event)" (add)="onSelectFlatsInBillWiseConsumption($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.flatNo }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.flatNo">{{ item?.flatNo }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="billwise_selectedFlatNumber.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportBillWiseConsumptionReports()"> Export
                        </button>
                      </div>
                    </div>
                  </div>

                </accordion-group>



                <accordion-group *ngIf="role == (listOfRoles.ADMIN)" [isOpen]="isDeviceCommunication"
                  heading="4. Device Communication Reports" class="accordion-item" #group4>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group4?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="deviceCommunication_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)"
                            (add)="onSelectUtilityInDeviceCommunication($event)" [multiple]="true"
                            [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="deviceCommunication_selectedUtility==0&&utilityValidationMessage"
                            class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date Range </label>
                          <div class="position-relative">
                            <input type="text" class="form-control datedeviceCommunication"
                              [value]="deviceCommunicationDateRange ? (formatDateRange(deviceCommunicationDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="deviceCommunicationDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarDeviceCommunication()"></span>
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="deviceCommunicationDateRange.length > 0"
                              (click)="clearDeviceCommunicationDate()"></span>

                          </div>
                          <!--  <div *ngIf="deviceCommunicationDateRange.length==0&&dateRangeValidationMessage"
                            class="text-danger mt-2">
                            {{ dateRangeValidationMessage }}
                          </div> -->
                        </div>
                      </div>


                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Flat Number <span class="required">*</span></label>
                          <ng-select [items]="flatnumbersList" placeholder="Select Flats" bindLabel="flatNo"
                            bindValue="id" [(ngModel)]="deviceCommunication_selectedFlatNumber"
                            (change)="onFilterFlatsChange($event)" (add)="onSelectFlatsInDeviceCommunication($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.flatNo }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.flatNo">{{ item?.flatNo }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="deviceCommunication_selectedFlatNumber.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Active Status <span class="required">*</span></label>

                          <ng-select [items]="Active_Status" placeholder="Select Active Status" bindLabel="name"
                            bindValue="id" [(ngModel)]="selectActivationStatus" (change)="onFilterFlatsChange($event)"
                            [multiple]="false" [clearable]="false" [deselectOnClick]="false">
                          </ng-select>
                          <div *ngIf="billwise_selectedFlatNumber.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">MCB<span class="required">*</span></label>
                          <ng-select [items]="Mcb_Status" placeholder="Select Mcb" bindLabel="name" bindValue="id"
                            [(ngModel)]="selecteddeviceCommunicationMcb" (change)="onFilterFlatsChange($event)"
                            [multiple]="false" [deselectOnClick]="false" [clearable]="false">
                          </ng-select>
                          <div *ngIf="selecteddeviceCommunicationMcb.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Valve Status<span class="required">*</span></label>
                          <ng-select [items]="Valve_Status" placeholder="Select Valve Status" bindLabel="name"
                            bindValue="id" [(ngModel)]="selectedValve_Status" (change)="onFilterFlatsChange($event)"
                            [multiple]="false" [deselectOnClick]="false" [clearable]="false">
                          </ng-select>
                          <div *ngIf="selectedValve_Status.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Communicating Status<span class="required">*</span></label>
                          <ng-select [items]="Communication_Status" placeholder="Select Communicating Status"
                            bindLabel="name" bindValue="id" [(ngModel)]="selectedCommnunicationStatus"
                            (change)="onFilterFlatsChange($event)" [multiple]="false" [deselectOnClick]="false"
                            [clearable]="false">
                          </ng-select>
                          <div *ngIf="selectedCommnunicationStatus.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>



                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportDeviceCommunicationReports()"> Export
                        </button>
                      </div>
                    </div>
                  </div>

                </accordion-group>



                <accordion-group *ngIf="role == (listOfRoles.ADMIN)" [isOpen]="false" heading="5.Meter Reading Reports"
                  class="accordion-item" #group5>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group5?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="meterReading_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)" (add)="onSelectUtilityInMeterReading($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="meterReading_selectedUtility==0&&utilityValidationMessage"
                            class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date Range <span class="required">*</span></label>
                          <div class="position-relative">
                            <input type="text" class="form-control datemeterReading"
                              [value]="meterReadingDateRange ? (formatDateRange(meterReadingDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="meterReadingDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarMeterReading()"></span>
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="meterReadingDateRange.length > 0"
                              (click)="clearMeterReadingDate()"></span>

                          </div>
                          <div *ngIf="meterReadingDateRange.length==0&&dateRangeValidationMessage"
                            class="text-danger mt-2">
                            {{ dateRangeValidationMessage }}
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Flat Number </label>
                          <ng-select [items]="flatnumbersList" placeholder="Select Flats" bindLabel="flatNo"
                            bindValue="id" [(ngModel)]="meterReading_selectedFlatNumber"
                            (change)="onFilterFlatsChange($event)" (add)="onSelectFlatsInMeterReadings($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.flatNo }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.flatNo">{{ item?.flatNo }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="meterReading_selectedFlatNumber.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>








                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportMeterReadingReports()"> Export
                        </button>
                      </div>
                    </div>
                  </div>

                </accordion-group>





                <accordion-group *ngIf="(role == listOfRoles.ADMIN)&& billingType =='Postpaid'" [isOpen]="false"
                  heading="6.Bill Generation Reports" class="accordion-item" #group6>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group6?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="billgeneration_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)" (add)="onSelectUtilityInMeterReading($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="billgeneration_selectedUtility==0&&utilityValidationMessage"
                            class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date Range <span class="required">*</span></label>
                          <div class="position-relative">
                            <input type="text" class="form-control datebillGeneration"
                              [value]="billGenerationDateRange ? (formatDateRange(billGenerationDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="billGenerationDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarBillGeneration()"></span>
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="billGenerationDateRange.length > 0"
                              (click)="clearBillDate()"></span>

                          </div>
                          <div *ngIf="billGenerationDateRange.length==0&&dateRangeValidationMessage"
                            class="text-danger mt-2">
                            {{ dateRangeValidationMessage }}
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Flat Number <span class="required">*</span></label>
                          <ng-select [items]="flatnumbersList" placeholder="Select Flats" bindLabel="flatNo"
                            bindValue="id" [(ngModel)]="billGeneration_selectedFlatNumber"
                            (change)="onFilterFlatsChange($event)" (add)="onSelectFlatsInBillGeneration($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.flatNo }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.flatNo">{{ item?.flatNo }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="billGeneration_selectedFlatNumber.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Status<span class="required">*</span></label>
                          <ng-select [items]="Valve_Status" placeholder="Select Valve Status" bindLabel="name"
                            bindValue="id" [(ngModel)]="selectedValve_Status_MeterReadings"
                            (change)="onFilterFlatsChange($event)" [multiple]="false" [deselectOnClick]="false"
                            [clearable]="false">
                          </ng-select>
                          <div *ngIf="selectedValve_Status_MeterReadings.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>





                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportBillGenerationReports()"> Export
                        </button>
                      </div>
                    </div>
                  </div>

                </accordion-group>



                <accordion-group
                  *ngIf="role === listOfRoles.ADMIN && (billingType === 'Prepaid' || (billingType === 'Postpaid' && walletStatus))"
                  [isOpen]="iswalletBalnceReport"
                  [heading]="billingType === 'Prepaid' ? '6. Consumer wallet Reports' : '7. Consumer wallet Reports'"
                  class="accordion-item" #group7>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group7?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date Range <span class="required">*</span></label>
                          <div class="position-relative">
                            <input type="text" class="form-control datewalletBalanceReport"
                              [value]="walletBalanceDateRange ? (formatDateRange(walletBalanceDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="walletBalanceDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarWalletBalance()"></span>
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="walletBalanceDateRange.length > 0"
                              (click)="clearBillDate()"></span>

                          </div>
                          <div *ngIf="walletBalanceDateRange.length==0&&dateRangeValidationMessage"
                            class="text-danger mt-2">
                            {{ dateRangeValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Status <span class="required">*</span></label>
                          <ng-select [items]="WalletBalance_Status" placeholder="Select Status" bindLabel="name"
                            bindValue="id" [(ngModel)]="selected_walletbalance" (change)="onFilteWalletChange($event)"
                            (add)="onSelectWalletType($event)" [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="selected_walletbalance.length==0&&flatNumberValidationMessage"
                            class="text-danger mt-2">
                            {{ flatNumberValidationMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Flat Number </label>
                          <ng-select [items]="flatnumbersList" placeholder="Select Flats" bindLabel="flatNo"
                            bindValue="id" [(ngModel)]="wallet_selectedFlatNumber"
                            (change)="onFilterWalletFlatsChange($event)" (add)="onSelectFlatsInWalletBalance($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.flatNo }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.flatNo">{{ item?.flatNo }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <!--  <div *ngIf="flatNumberValidationMessage" class="text-danger mt-2">
                          {{ flatNumberValidationMessage }}
                        </div> -->
                        </div>
                      </div>



                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportWalletBalanceReports()"> Export
                        </button>
                      </div>
                    </div>
                  </div>

                </accordion-group>


                <accordion-group
                  *ngIf="role === listOfRoles.ADMIN && (billingType === 'Prepaid' || (billingType === 'Postpaid' && walletStatus))"
                  [isOpen]="false"
                  [heading]="billingType === 'Prepaid' ? '7. Wallet History Reports' : '8. Wallet History Reports'"
                  class="accordion-item" #group8>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group8?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">


                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Date Range <span class="required">*</span></label>
                          <div class="position-relative">
                            <input type="text" class="form-control datewalletHistory"
                              [value]="walletHistoryDateRange ? (formatDateRange(walletHistoryDateRange)) : ''"
                              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                              [bsConfig]="datePickerConfig" [(bsValue)]="walletHistoryDateRange" />
                            <span class="bx bx-calendar inputIcon" (click)="openCalendarWalletHistory()"></span>
                            <span class="bx bx-x inputIcon clear-icon" *ngIf="walletHistoryDateRange.length > 0"
                              (click)="clearBillDate()"></span>

                          </div>
                          <div *ngIf="walletHistoryDateRange.length==0&&dateRangeValidationMessage"
                            class="text-danger mt-2">
                            {{ dateRangeValidationMessage }}
                          </div>
                        </div>
                      </div>





                      <!-- 
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="walletHistory_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)" (add)="onSelectUtilityInMeterReading($event)"
                            [multiple]="true" [deselectOnClick]="true">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>

                          </ng-select>
                          <div *ngIf="walletHistory_selectedUtility==0&&utilityValidationMessage"
                            class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div> -->



                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Utilities <span class="required">*</span></label>
                          <ng-select [items]="utilityList" placeholder="Select Utilities" bindLabel="name"
                            bindValue="id" [(ngModel)]="walletHistory_selectedUtility"
                            (change)="onFilterUtilitiesChange($event)"
                            (add)="onSelectUtilityInWalletHistoryReport($event)" [multiple]="true"
                            [deselectOnClick]="true">

                            <!-- Template for displaying selected items with cross icon -->
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <!-- Cross icon to clear the selected item -->
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <!-- Multi-label template for displaying multiple selected items -->
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0, 2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                <!-- Cross icon for removing items -->
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>

                          <div *ngIf="walletHistory_selectedUtility == 0 && utilityValidationMessage"
                            class="text-danger mt-2">
                            {{ utilityValidationMessage }}
                          </div>
                        </div>
                      </div>






                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportWalletHistoryReports()"> Export
                        </button>
                      </div>
                    </div>
                  </div>

                </accordion-group>



                <accordion-group
                  *ngIf="role === listOfRoles.ADMIN && (billingType === 'Prepaid' || (billingType === 'Postpaid' && walletStatus))"
                  [isOpen]="false"
                  [heading]="billingType === 'Prepaid' ? '8. Month Balance report' : '9. Month Balance report'"
                  class="accordion-item" #group3>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                    <i class="mdi font-size-24"
                      [ngClass]="!group3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon' : 'mdi mdi-chevron-up accor-plus-icon'"></i>
                  </span>
                  <div class="text-muted">
                    <div class="row">


                      <!-- <div class="col-lg-3">
                      <div class="mb-3">
                        <label class="form-label">Months <span class="required">*</span></label>
                        <div class="position-relative">
                          <ng-select [items]="monthsListWithAll" placeholder="Select Month" bindLabel="name" bindValue="id"
                            [(ngModel)]="selectedMonthsForWalletBalanceReport" (change)="onFilterMonthsChange($event)" (add)="onSelectMonthsForWalletBalanceReports($event)" [multiple]="true"
                            [deselectOnClick]="true" [clearable]="false">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                              <span class="ng-value-label">{{ item?.name }}</span>
                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                              <div class="ng-value" *ngFor="let item of items.slice(0,2)">
                                <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                              </div>
                              <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                              </div>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="selectedMonth==null&&monthValidationMessage" class="text-danger mt-2">
                            {{ monthValidationMessage }}
                          </div>
                        </div>
                      </div>
                    </div> -->

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Months <span class="required">*</span></label>
                          <div class="position-relative">
                            <ng-select [items]="monthsListWithAll" placeholder="Select Month" bindLabel="name"
                              bindValue="id" [(ngModel)]="selectedMonthsForWalletBalanceReport"
                              (change)="onFilterMonthsChange($event)"
                              (add)="onSelectMonthsForWalletBalanceReports($event)" [multiple]="true"
                              [deselectOnClick]="true" [clearable]="true">

                              <!-- Template for displaying selected items with cross icon -->
                              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">{{ item?.name }}</span>
                                <!-- Cross icon to clear the selected item -->
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </ng-template>

                              <!-- Multi-label template for displaying multiple selected items -->
                              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items.slice(0, 2)">
                                  <span class="ng-value-label" *ngIf="item?.name">{{ item?.name }}</span>
                                  <!-- Cross icon for removing items -->
                                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </div>
                                <div class="ng-value" *ngIf="items.length > 2">
                                  <span class="ng-value-label">+ {{ items.length - 2 }} more</span>
                                </div>
                              </ng-template>
                            </ng-select>

                            <div *ngIf="selectedMonth == null && monthValidationMessage" class="text-danger mt-2">
                              {{ monthValidationMessage }}
                            </div>
                          </div>
                        </div>
                      </div>




                      <div class="col-lg-3 d-flex align-items-center sm-btn">
                        <button class="btn btn-primary btn-md mt-2" (click)="exportMonthWiseWalletBalnceReports()">
                          Export
                        </button>
                      </div>
                    </div>
                  </div>

                </accordion-group>



              </accordion>


            </div>
          </div>
        </div>

      </div>


    </div>





  </div>


</div>